/* App.css */

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: black;
  color: white;
  justify-content: space-between; /* Распределение контента с пространством между элементами */
  text-align: center;
}

.header {
  height: 2px;
  background-image: linear-gradient(to right, #f9d423 0%, #ff4e50 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
/* Убедитесь, что остальные части вашего CSS соответствуют общему стилю приложения */


.main {
  flex-grow: 1; /* Основное содержимое занимает все доступное пространство */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Центрирование содержимого внутри основного блока */
  align-items: center;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-logo {
  max-width: 300px;
  width: 100%;
  margin-bottom: 0px; /* Отступ между логотипом и текстом */
}

.development-text {
  font-size: 30px;
  font-weight: bold;
  color: #FFD700;
  transform: translate(0px, -50px);
}

.header::before, .footer::before {
  color: white;
}

/* App.css */

/* ... (остальные стили) ... */

@keyframes move-infinity {
  0% {
      transform: translateX(0) scale(1);
  }

  13% {
      transform: translateX(25px) translateY(25px) scale(0.5);
  }

  26% {
      transform: translateX(50px) scale(1);
  }

  39% {
      transform: translateX(25px) translateY(-25px) scale(0.5);
  }

  52% {
      transform: translateX(0px) translateY(-50px) scale(1);
  }

  65% {
      transform: translateX(25px) translateY(-75px) scale(0.5);
  }

  78% {
      transform: translateX(50px) translateY(-50px) scale(1);
  }

  91% {
      transform: translateX(25px) translateY(-25px) scale(0.5);
  }
}

.infinity-path {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translate(-35px, 0%);
}

  .infinity-path::before,
  .infinity-path::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 15px;
      background: red;
      border-radius: 50%;
      animation: move-infinity 5s infinite;
  }

  .infinity-path::after {
      animation-direction: alternate-reverse;
  }


  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #000; /* Абсолютно черный цвет футера */
  }
  
  .footer-left, .footer-right {
    flex: 1; /* Обеспечиваем гибкость размеров */
    min-width: 5%; /* Минимальная ширина для сохранения отступов */
    text-align: left;
    margin: 0 5%; /* Добавляем отступы от краев экрана */
  }
  
  .footer-left a, .footer-right a {
    color: #fff; /* Цвет текста ссылок */
    text-decoration: none; /* Убираем подчеркивание у ссылок */
    display: block; /* Ссылки занимают всю ширину строки */
    margin-bottom: 8px; /* Отступ между ссылками */
  }
  
  .footer-middle a {
    text-decoration: none; /* Убираем подчеркивание у ссылки */
  }
  
  .linkedin-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px; /* Увеличен размер кружка */
    height: 60px; /* Увеличен размер кружка */
    border-radius: 50%;
    background-color: #353333; /* Цвет кружка по дефолту как текущий цвет футера */
    color: #fff; /* Цвет текста иконки */
    font-weight: bold;
    font-size: 36px; /* Увеличенный размер шрифта для "in" */
    transition: background-color 0.3s ease; /* Плавная анимация при наведении */
  }
  
  .linkedin-icon:hover {
    background-color: #ff5722; /* Рыжий цвет при наведении */
  }
  
  
  
